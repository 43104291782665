<template>
    <section class="agenda">
        <a id="agenda"></a>
        <!-- <title>Próximos Eventos</title> -->
        <section class="preview">
            <div v-for="event in getActiveEvents()" :key="event.id">
                <EventCard :event="event" />
            </div>
        </section>
    </section>
</template>

<script>
import eventsData from '../assets/events.json'
import EventCard from './EventCard.vue';

export default {
    name: 'AgendaPreview',
    components: {
        EventCard
    },
    methods: {
        getActiveEvents: function () {            
            let data = eventsData.data;
            let events = data.filter(e => this.isEventActive(e));
            return events;
        },

        isEventInPast: function (event) {
            return false;
        },

        isEventActive: function (event) {
            debugger
            if (!event)
                return false;

            if (event.active === undefined)
                return true;

            return event.active
        }
    }
}
</script>

<style scoped>
.agenda {
    display: block;
    max-width: 85rem;
    margin: 0 auto;

    title {
        display: block;
        font-size: 2rem;
        padding: 1rem 0 0 0;
        font-weight: bold;
        color: #ffffffc4;
    }

    .preview {
        /* border: 1px solid white; */
        padding: 2rem;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        
        gap: 2rem;


        h2 {
            color: white;
        }
    }
}
</style>