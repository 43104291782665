<template>
    <section class="event" :style="customCSS()">
        <a :href="url()" target="_blank">
            <header>
                <section class="date">
                    <span class="label">{{ dayOfWeek() }}</span>
                    <span class="day">{{ day() }}</span>
                    <span class="label">{{ month() }}</span>
                </section>
                <title>{{ event.title }}</title>
            </header>
            <section class="content">
                <p v-if="event.description">{{ event.description }}</p>
            </section>
        </a>
    </section>
</template>

<script>
const MONTHS = [
    "JANEIRO", "FEVEREIRO", "MARCO", "ABRIL", "MAIO", "JUNHO", "JULHO",
    "AGOSTO", "SETEMBRO", "OUTUBRO", "NOVEMBRO", "DEZEMBRO"
]

const DAY_OF_WEEK = ["DOMINGO", "SEGUNDA", "TERÇA", "QUARTA", "QUINTA", "SEXTA", "SÁBADO"]

export default {
    name: 'EventCard',
    props: {
        event: Object,
    },
    methods: {
        pad: function (number) {
            if (number >= -9 && number <= 9)
                return '0' + number;
            return '' + number
        },
        day: function () {
            return this.pad(new Date(this.event.date).getDate())
        },
        month() {
            const date = new Date(this.event.date)
            return MONTHS[date.getMonth()]
        },
        dayOfWeek: function () {
            const date = new Date(this.event.date)
            return DAY_OF_WEEK[date.getDay()]
        },
        customCSS: function () {
            return this.event.image
                ? { backgroundImage: `url(\'${this.event.image}\')` }
                : {}
        },
        url: function () {
            return this.event.url
                ? this.event.url
                : null
        }
    }
}
</script>

<style scoped>
.event:hover {
    border-color: red;
    transition: border .4s ease;
}

.event {
    border: 2px solid rgb(90, 90, 90);
    border-radius: .5rem;
    background-color: #ff000025;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 25rem;
    height: 25rem;
    max-height: 23rem;
    overflow: hidden;

    transition: border .4s ease;

    a {
        text-decoration: none;

    }

    header {
        position: relative;

        .date {
            position: absolute;
            top: .0rem;
            left: .7rem;
            display: inline-block;
            background-color: rgba(255, 255, 255, 1);
            padding: .25rem;
            font-size: 2rem;
            display: flex;
            flex-direction: column;
            border-radius: 0 0 .3rem .3rem;
            color: black;
            z-index: 10;

            .day {
                font-weight: bold;
                font-size: 2.3rem;
                font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
            }

            .label {
                font-size: .55rem;
                text-transform: uppercase;
            }
        }

        title {
            position: relative;
            left: 0rem;            
            display: block;
            color: white;
            text-align: left;
            background-color: rgba(0, 0, 0, 0.7);
            padding: .5rem;
            padding-left: 5rem;
            font-size: 1.2rem;
            font-weight: bold;
            font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif
        }
    }

    .content {
        display: block;
        padding: 18rem 0rem 0 0rem; 
        color: #ffffff;
        text-shadow: 1px 1px 1px black;

        p {                        
            background-color: rgba(0, 0, 0, .6);
            font-size: small;
            padding: .5rem;
        }
    }
}

@media screen and ((max-width : 320px) or (max-width : 600px) or (max-width : 992px)) {
    .event {
        header {
            title {
                font-size: 1rem;
            }
        }
    }
}

@media screen and (max-width : 600px) {
    .event {
        width: 98vw;        
    }
}

</style>